
import { getCheckoutSession } from '@/services/Stripe';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    payment: {
      type: String,
      required: true,
    },
  },
  async setup(props) {
    const stripeSession = await getCheckoutSession(props.payment || '');
    const classInfo = ref(false);

    return {
      stripeSession,
      classInfo,
    };
  },
});
