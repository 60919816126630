
import moment from 'moment';
import useOrder from '@/store/order';
import { defineComponent, ref } from 'vue';
import idSvg from '@/assets/id.svg';
import { Order, OrderStates } from '@/types/functions';
import TransactionDetail from '@/components/TransactionDetail.vue';
import Modal from '@/components/Modal.vue';
import Loader from '@/components/Loader.vue';

export default defineComponent({
  components: {
    TransactionDetail,
    Modal,
    Loader,
  },
  async setup() {
    const { getOrders, orders } = useOrder();
    const transactionDetail = ref(false);
    const selectedPayment = ref('');

    function showTransactionDetail(order: Order): void {
      transactionDetail.value = true;
      selectedPayment.value = order.payment || '';
    }

    await getOrders();

    return {
      orders,
      selectedPayment,
      idSvg,
      OrderStates,
      moment,
      transactionDetail,
      showTransactionDetail,
    };
  },
});
