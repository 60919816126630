<template>
  <ul class="orders-list">
    <li class="orders-list__header">
      <div class="orders-list__order orders-list__order--name">
        <span>Jméno</span>
      </div>

      <div class="orders-list__order orders-list__order--email">
        <span>E-mail</span>
      </div>

      <div class="orders-list__order orders-list__order--phone">
        <span>Telefon</span>
      </div>

      <div class="orders-list__order orders-list__order--result-type">
        <span>Typ výsledku</span>
      </div>

      <div class="orders-list__order orders-list__order--state">
        <span>Stav</span>
      </div>
    </li>

    <li v-for="order in orders" :key="order.id">
      <div class="orders-list__header">
        <span><b>{{ moment(order.created).format('DD.MM.YYYY HH:mm') }}</b></span>
        <button
          @click="showTransactionDetail(order)"
          :disabled="order.state === OrderStates.NEW"
          class="button"
        >
          Detail transakce
        </button>
      </div>

      <div class="orders-list__main-info">
        <div class="orders-list__order orders-list__order--name">
          <span>{{ order.name }}</span>
        </div>

        <div class="orders-list__order orders-list__order--email">
          <span><a :href="`mailto:${order.email}`">{{ order.email }}</a></span>
        </div>

        <div class="orders-list__order orders-list__order--phone">
          <span><a :href="`tel:${order.phone}`">{{ order.phone }}</a></span>
        </div>

        <div class="orders-list__order orders-list__order--result-type">
          <span v-if="order.resultType === 'NOT_SELECTED'">Nevybráno</span>
          <span v-else-if="order.resultType === 'PERSON_CONSULTATION'">Osobní</span>
          <span v-if="order.resultType === 'PHONE_CONSULTATION'">Telefonický</span>
          <span v-if="order.resultType === 'EMAIL_CONSULTATION'">Do e-mailu</span>
        </div>

        <div class="orders-list__order orders-list__order--state">
          <span v-if="order.state === OrderStates.PAID" style="color: green;">Zaplaceno</span>
          <span v-else-if="order.state === OrderStates.NEW" style="color: orange;">Nová objednávka</span>
          <span v-if="order.state === OrderStates.PAYMENT_FAILED" style="color: red;">Platba se nazdařila</span>
          <span v-if="order.state === OrderStates.PAYMENT_PROCESSING" style="color: grey;">Platba probíhá...</span>
        </div>
      </div>

      <div class="orders-list__payment">
        <span>ID Stripe transakce: <b>{{ order.payment || '---' }}</b></span>
      </div>
    </li>
  </ul>

  <Modal v-model="transactionDetail" v-if="transactionDetail">
    <Suspense>
      <template #default>
        <TransactionDetail :payment="selectedPayment"/>
      </template>

      <template #fallback>
        <Loader/>
      </template>
    </Suspense>
  </Modal>
</template>

<script lang="ts">
import moment from 'moment';
import useOrder from '@/store/order';
import { defineComponent, ref } from 'vue';
import idSvg from '@/assets/id.svg';
import { Order, OrderStates } from '@/types/functions';
import TransactionDetail from '@/components/TransactionDetail.vue';
import Modal from '@/components/Modal.vue';
import Loader from '@/components/Loader.vue';

export default defineComponent({
  components: {
    TransactionDetail,
    Modal,
    Loader,
  },
  async setup() {
    const { getOrders, orders } = useOrder();
    const transactionDetail = ref(false);
    const selectedPayment = ref('');

    function showTransactionDetail(order: Order): void {
      transactionDetail.value = true;
      selectedPayment.value = order.payment || '';
    }

    await getOrders();

    return {
      orders,
      selectedPayment,
      idSvg,
      OrderStates,
      moment,
      transactionDetail,
      showTransactionDetail,
    };
  },
});
</script>

<style lang="scss" scoped>
.orders-list {
  li {
    margin-bottom: 20px;
    background-color: $color-light-grey;
    border-radius: 5px;
    word-break: break-word;

    span {
      display: block;
      overflow: scroll;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    background-color: $color-blue !important;
    color: $color-white;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    background-color: $color-light-grey;
    border-radius: 5px;
    overflow: hidden;
    text-align: left;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__main-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__order {
    overflow: hidden;
    padding: 20px 10px;
    border-right: 1px solid rgba($color-dark-grey, 0.2);
    white-space: nowrap;

    a {
      color: $color-blue;

      &:hover {
        color: $color-yellow;
      }
    }

    @media screen and (max-width: 600px) {
      border-right: none;
      padding: 5px 5px;
    }

    &:last-child {
      border-right: none;
    }

    &--name {
      flex: 0 0 calc(20% - 20px);
    }

    &--email {
      flex: 0 0 calc(20% - 20px);
    }

    &--phone {
      flex: 0 0 calc(20% - 20px);
    }

    &--result-type {
      flex: 0 0 calc(20% - 20px);
    }

    &--state {
      flex: 0 0 calc(20% - 20px);
    }
  }

  &__header {
    border-bottom: 1px solid rgba($color-dark-grey, 0.2);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 600px) {
      flex-direction: initial;
    }
  }

  &__payment {
    border-top: 1px solid rgba($color-dark-grey, 0.2);
    padding: 10px;

    span {
      overflow: scroll !important;
    }
  }
}
</style>
