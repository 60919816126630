
import { defineComponent } from 'vue';
import Loader from '@/components/Loader.vue';
import OrdersList from '@/components/OrdersList.vue';

export default defineComponent({
  components: {
    Loader,
    OrdersList,
  },
});
