
import { defineComponent, onBeforeUnmount } from 'vue';
import closeSvg from '@/assets/close.svg';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    'update:modelValue': (payload: boolean) => payload,
  },
  setup() {
    const body = document.querySelector('body') as HTMLBodyElement;

    body.classList.add('modal-open');

    onBeforeUnmount(() => {
      body.classList.remove('modal-open');
    });

    return {
      closeSvg,
    };
  },
});
