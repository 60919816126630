<template>
  <div class="transaction-detail">
    <h2>Detail Stripe transakce</h2>
    <ul>
      <li>
       <b>ID transakce</b>
       {{ stripeSession.id }}
      </li>
      <li>
       <b>Celková částka (jednotky)</b>
       {{ stripeSession.amount_total || '-' }}
      </li>
      <li>
       <b>Měna</b>
       {{ stripeSession.currency }}
      </li>
      <li>
       <b>E-mail zákazníka</b>
       {{ (stripeSession.customer_details || {}).email || '-' }}
      </li>
      <li>
       <b>ID Stripe zákazníka</b>
       {{ stripeSession.customer || '-' }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { getCheckoutSession } from '@/services/Stripe';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    payment: {
      type: String,
      required: true,
    },
  },
  async setup(props) {
    const stripeSession = await getCheckoutSession(props.payment || '');
    const classInfo = ref(false);

    return {
      stripeSession,
      classInfo,
    };
  },
});
</script>

<style lang="scss" scoped>
.transaction-detail {
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    background-color: rgba($color-blue, 0.4);
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    overflow: hidden;
    word-break: break-word;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    max-height: 0;
    transition: max-height .5s ease-out;
    overflow: hidden;

    &--showed {
      max-height: 1000px;
      transition: max-height .5s ease-in;
    }

    p {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}
</style>
