<template>
  <div class="orders">
    <h1>Objednávky</h1>

    <Suspense>
      <template #default>
        <OrdersList/>
      </template>

      <template #fallback>
        <Loader/>
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Loader from '@/components/Loader.vue';
import OrdersList from '@/components/OrdersList.vue';

export default defineComponent({
  components: {
    Loader,
    OrdersList,
  },
});
</script>

<style lang="scss" scoped>
.orders {
  min-height: 100vh;
}
</style>
