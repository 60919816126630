<template>
  <div class="modal" @click.self="$emit('update:modelValue', false)">
    <div class="modal__content">
      <nav class="modal__nav">
        <button @click="$emit('update:modelValue', false)">
          <img :src="closeSvg" alt="Zavřít okno" width="15" height="15"/>
        </button>
      </nav>
      <slot/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount } from 'vue';
import closeSvg from '@/assets/close.svg';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: {
    'update:modelValue': (payload: boolean) => payload,
  },
  setup() {
    const body = document.querySelector('body') as HTMLBodyElement;

    body.classList.add('modal-open');

    onBeforeUnmount(() => {
      body.classList.remove('modal-open');
    });

    return {
      closeSvg,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);

  &__content {
    position: relative;
    width: 80vw;
    height: 80vh;
    background-color: white;
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    overflow: scroll;

    @media (orientation: portrait) {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }

  &__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    button {
      background-color: transparent;
    }
  }
}
</style>
